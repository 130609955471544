
import { apiExpressLists, apiExpressDel, apiSwitchShop } from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import PopoverSelect from "@/components/popover-select.vue";
import { apiShopLists } from "@/api/shop";
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    PopoverSelect,
  },
})
export default class ExpressList extends Vue {
  /** S Data **/

  apiExpressLists = apiExpressLists;
  searchObj: Object = {
    name: "",
    deal_sid: "",
  };
  pager: RequestPaging = new RequestPaging();
  tableData = [];
  shopList: any = [];
  categoryList: any[] = [];
  /** E Data **/

  /** S Methods **/

  // 获取模型列表
  getExpressLists(): void {
    this.pager
      .request({
        callback: apiExpressLists,
        params: {
          ...this.searchObj,
        },
      })
      .then((res) => {
        this.tableData = res.lists;
      });
  }
  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getExpressLists();
  }
  // 删除商城
  async onExpressDelete(row: any): Promise<void> {
    await apiExpressDel({ id: row.id });
    this.getExpressLists();
  }

  // 去编辑
  toExpressEdit(id: number | any): void {
    this.$router.push({
      path: "/logistics/express_edit",
      query: {
        id: id as any,
        mode: PageMode.EDIT,
      },
    });
  }

  toRuleEdit(id: number | any): void {
    this.$router.push({
      path: "/logistics/express_rule",
      query: {
        id: id as any,
      },
    });
  }

  getRuleName(arr: any) {
    let name = [];
    for (let i of arr) {
      name.push(i.country_code);
    }
    return name.join(",");
  }

  switchShop(event: any, id: any) {
    apiSwitchShop({ id: id, deal_sid: event }).then((res) => {
      this.$message.success("操作成功");
      this.getExpressLists();
    });
  }

  /** E Methods **/

  /** S ods **/
  created() {
    this.getExpressLists();
    apiShopLists({ page_size: 9999 }).then((res) => {
      this.shopList = res.lists;
      this.shopList.unshift({ id: "", name: "全部" });
    });
  }
}
